import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container:{
    display:'flex',
    height:'100%',
    justifyContent:'center',
    alignItems:'center'
  },
  textinput:{
    backgroundColor:'white',
    paddingHorizontal:10,
    paddingVertical:5
  },
  button:{
    backgroundColor:'blue',
    paddingHorizontal:10,
    paddingVertical:5,
  }
})

export { styles }