import { View, ViewStyle } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"


interface ContainerProps {
  children:React.ReactNode
  style?:ViewStyle
}

const Container = ({children,style}:ContainerProps) => {
  return (
    <SafeAreaView>

      <View style={[{marginHorizontal:10,minHeight:'90vh',marginVertical:10},{...style}]} >
        {children}
      </View>
    </SafeAreaView>

  )
}

export default Container