import { Image, ImageBackground, SafeAreaView, ScrollView, Text, View } from "react-native"
import { theme } from "../../utils/theme"


const Card = ({watch,selectedType,image}) => {
  const { borderRadius }  = theme
  return (
    <>
      {/* <LinearGradient
        style={{height:'100%',paddingBottom:10}}
        colors={['#AA4EFE', '#2B2952']}
      > */}
      <View style={{}} >

      <SafeAreaView style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%',width:'100%',padding:10}} >
        <ScrollView style={{paddingHorizontal:30,width:'100%'}} horizontal={true}>
        {selectedType == 'BLACK' &&(
        <View style={{width:300,display:'flex',justifyContent:'center',alignItems:'center'}} >
          <Text style={{textAlign:'center',color:'black'}} >Voorkant</Text>
          <View style={{width:250,aspectRatio:855/540,borderRadius}} >

            <ImageBackground style={{borderRadius,position:'absolute',width:'100%',height:'100%',}} resizeMode="cover"  source={require('./../../assets/black.png')} />
            <View style={{display:'flex',flexDirection:'row',height:'100%'}} >

              <View style={{padding:10,display:'flex',flexDirection:'column',justifyContent:'space-between',height:'100%'}} >
                
                <View style={{height:40}} >
                  <Text style={{color:'white',fontSize:12}} >{watch('item0')}</Text>
                  <Text style={{color:'white',fontSize:9}} >{watch('item1')}</Text>
                </View>

                <View style={{height:40}} >
                  <Text style={{color:'white',fontSize:9,}} >{watch('item2')}</Text>
                  <Text style={{color:'white',fontSize:9}} >{watch('item3')}</Text>
                </View>

                <View style={{height:40}} >
                  <Text style={{color:'white',fontSize:9}} >{watch('item4')}</Text>
                  <Text style={{color:'white',fontSize:9}} >{watch('item5')}</Text>
                </View>
                <Text style={{color:'white',fontSize:9}} >{watch('item6')}</Text>
              </View>

              {watch('qrcode') && <Image source={require('./../../assets/white-qrcode.png')} style={{width:50,height:50,position:'absolute',right:10,bottom:10  }}  />}
            </View>


          </View>
        </View>
        )}
        {selectedType == 'WOOD' &&(
          <>
        <View style={{width:300,display:'flex',justifyContent:'center',alignItems:'center'}} >
            <Text style={{textAlign:'center',color:'black'}} >Voorkant</Text>
            <View style={{width:250,aspectRatio:855/540,borderRadius}} >
              <ImageBackground style={{borderRadius,position:'absolute',width:'100%',height:'100%'}} resizeMode="cover"  source={require('./../../assets/wood.png')} />

              <View style={{padding:10,display:'flex',flexDirection:'column',justifyContent:'space-between',height:'100%'}} >
                
                <View style={{height:40}} >
                  <Text style={{color:'#C0C09F',fontSize:12}} >{watch('item0')}</Text>
                  <Text style={{color:'#C0C09F',fontSize:9}} >{watch('item1')}</Text>
                </View>

                <View style={{height:40}} >
                  <Text style={{color:'#C0C09F',fontSize:9,}} >{watch('item2')}</Text>
                  <Text style={{color:'#C0C09F',fontSize:9}} >{watch('item3')}</Text>
                </View>

                <View style={{height:40}} >
                  <Text style={{color:'#C0C09F',fontSize:9}} >{watch('item4')}</Text>
                  <Text style={{color:'#C0C09F',fontSize:9}} >{watch('item5')}</Text>
                </View>
                <Text style={{color:'#C0C09F',fontSize:9}} >{watch('item6')}</Text>

                {watch('qrcode') && <Image source={require('./../../assets/wood-qrcode.png')} style={{width:50,height:50,position:'absolute',right:10,bottom:10  }}  />}
              </View>
              
            </View>
          </View>

          
          <View style={{width:300,display:'flex',justifyContent:'center',alignItems:'center',marginRight:5}} >
             <Text style={{textAlign:'center',color:'black'}} >Achterkant</Text>
            <View style={{marginLeft:5,width:250,aspectRatio:855/540,backgroundColor:'black',margin:5,borderRadius}} >
            <ImageBackground style={{borderRadius,position:'absolute',width:'100%',height:'100%',backgroundColor:'red'}} resizeMode="cover"  source={require('./../../assets/wood.png')} />
              <View style={{display:'flex',flexDirection:'column',justifyContent:'center',height:'100%',padding:15,alignItems:'center'}} >
                <View style={{borderRadius,alignItems:'center', display:'flex',justifyContent:'center',borderColor:'#C0C09F',borderWidth:(image?.length) ? 0 : 0.5,height:'80%',width:'80%',backgroundColor:(image?.length) ? 'rgba(0,0,0,0)' :'rgba(0,0,0,0.1)'}} >

                  {image?.length
                  ?
                  <>
                  <Image  style={{}} width={100} height={100} source={{uri:image,width:100,height:100}} />
                  </>
                  : <Text style={{color:'#C0C09F'}} >Jouw logo hier</Text>
                  }
                  
                </View>
              </View>
            </View>
          </View>
          </>
          )}

        </ScrollView>
      </SafeAreaView>
      </View>

      {/* </LinearGradient> */}
    </>
  )
}

export default Card