import { Control, Controller, FieldValues } from "react-hook-form"
import { Text, TextInput, View } from "react-native"
import { styles } from "../styles"

interface InputProps {
  name:string
  control:Control<FieldValues, any>
  label:string
  placeholder?:string
}
const Input = ({control,name,label,placeholder}:InputProps) => {
  return (
    <View style={{marginVertical:10}} >
      <Text style={{textAlign:'left',width:'auto',opacity:0.5,marginBottom:5}} >{label}</Text>
      <Controller
      name={name}
      control={control}
      render={({field:{value,onChange}}) => (
        <TextInput
          autoCapitalize="none"
          placeholder={placeholder}
          value={value}
          onChangeText={onChange}
          style={[styles.textinput,{borderColor:'#D9D9D6',borderWidth:1,borderStyle:'solid'}]}
          />
      )}
      />
    </View>
  )
}

export default Input