import { Text, View } from "react-native";
import Button from "../../components/button/Button";
import { getAuth, signOut } from 'firebase/auth'
import { useFirebase } from "../../utils/firebase";
import Container from "../../components/containers/Container";
import Heading from "../../components/text/Heading";


const LogoutView = () => {
  useFirebase()
  const logout = async () => {
    const auth = getAuth();
    await signOut(auth)
      .then(console.log)
      .catch(console.log)
  }
  return (
    <Container style={{display:'flex'}} >
      <Heading style={{flex:2}} label="Are you sure you want to logout?" />
      <Button label="Logout" onPress={logout} />
    </Container>
  )
}

export default LogoutView;