
import {StyleProp, Text, TouchableOpacity, ViewStyle} from 'react-native'
import { styles as ThemeStyles } from '../styles'




const Button = ({onPress,label,style,isLoading}:{
  onPress:() => void
  label:string,
  style?:ViewStyle
  isLoading?:boolean
}) => {
  return (
    <TouchableOpacity  disabled={isLoading} style={[ThemeStyles.button,{paddingVertical:10,opacity:(isLoading) ? 0.5 : 1},style]} onPress={onPress} >
      <Text style={{textAlign:'center',color:'white'}} >{label}</Text>
    </TouchableOpacity>
  )
}
export default Button