import { Text, TextStyle, View, ViewStyle } from "react-native";

interface HeadingProps{
  label:string
  style?:TextStyle
}
export default function Heading({label,style}:HeadingProps){
  return (
    <Text style={[{fontSize:32,fontWeight:'bold'},style]} >{label}</Text>
  )

}