import CreateCard from "./screens/create/CreateCard";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import LoginView from "./screens/LoginView";
import {useFirebase} from './utils/firebase'
import UserProvider, { UserContext, useUser } from "./context/UserContext";
import HomeView from "./screens/HomeView/HomeView";
import LogoutView from "./screens/LogoutView/LogoutView";
import { Text, View } from "react-native";
import Button from "./components/button/Button";
import Header from "./components/Header/Header";
import CodeView from "./screens/qrcode/CodeView";
import RegisterView from "./screens/register/RegisterView";
import ViewerView from "./screens/public/ViewerView";
import CodesView from "./screens/qrcode/CodesView";

const Stack = createNativeStackNavigator();


const Provider = ({children}:{children:React.ReactChild}) => {
  return (
    <UserProvider>
      {children}
    </UserProvider>
  )
}


const MainStack = () => {
  const {user} = useUser();
  return (
    <NavigationContainer
    documentTitle={{}}


    linking={{
      prefixes:["https://cardcreate.aimdirect.com"],
      config:{
        screens:{
          Home:'/',
          Login:'login',
          Logout:'logout',
          Register:'register',
          Viewer:'viewer',
          Code:'code',
        }
      }
      }} >
    <Stack.Navigator
    initialRouteName="Home"
    screenOptions={{header:Header}} 
    >
      <Stack.Screen options={{headerShown:(user==null) ? false : true}} name="Code" component={CodeView} />
      {Boolean(user?.uid) ?
      <>
        <Stack.Screen  name="Home" component={CodesView} />
        <Stack.Screen name="Logout" component={LogoutView} />
      </>
      :
      <>
        <Stack.Screen options={{headerShown:false}} name="Home" component={CreateCard} />
        <Stack.Screen name="Login" component={LoginView} />
        <Stack.Screen options={{headerShown:false}} name="Register" component={RegisterView} />
      </>
      }
      <Stack.Screen options={{headerShown:false}} name="Viewer" component={ViewerView} />
    </Stack.Navigator>
    </NavigationContainer>
  )
}
export default function App(){
  
  

  return (
    <>
    <Provider>
      <MainStack />
    </Provider>
    </>
  )
}