import { User } from "firebase/auth";
import { addDoc, collection, doc, getDoc, getFirestore } from "firebase/firestore";
import { useContext, useEffect } from "react";
import { Text, View } from "react-native";
import { UserContext } from "../../context/UserContext";




const UseInternalAnalytics = () => ({
  addCodeView: async(code: string,uid:string) => {
    await addDoc(
      collection(getFirestore(),'users',uid,'codes',code,'analytics'),
      {
        createdAt: new Date(),
      }
    )

  }
})

export default function ViewerView({ route, navigation }){
  const {user} = useContext(UserContext) 
  const {addCodeView} = UseInternalAnalytics()
  useEffect(() =>{
    const { code, uid } = route.params;
    getDoc(
      doc(getFirestore(),'users',uid,'codes',code)
    )
    .then(async(snapshot) => {
      await addCodeView(code,uid)
      window.location.replace(`https://${snapshot.data()!.url}`)
    })
  },[])
  return (
    <View style={{padding:5,backgroundColor:'white'}} >
      <Text>Viewer</Text>
    </View>
  )
}


