import { getApps, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore'

const config = {
  apiKey: "AIzaSyB0q5Uuvk6l5F3ntEDC0v7CSdAIGkkSiaI",
  authDomain: "cardcreate-ae3e9.firebaseapp.com",
  projectId: "cardcreate-ae3e9",
  storageBucket: "cardcreate-ae3e9.appspot.com",
  messagingSenderId: "379424845547",
  appId: "1:379424845547:web:f32e9c8c958d4a383e18ae",
  measurementId: "G-GKDEJWJXD7"
}

const useFirebase = () => {
  if(!getApps().length){
    const app = initializeApp(config);
    const auth = getAuth(app)
    const functions = getFunctions(app)
    console.log('getApps().length',getApps().length,!getApps().length)
    const db = getFirestore()
    if(__DEV__){
      connectFunctionsEmulator(functions,'localhost',5001)
      connectAuthEmulator(auth,'http://localhost:9099')
      connectFirestoreEmulator(db,'localhost',8080)
    }
  }
}
export {useFirebase}