import { collection, getDocs, getFirestore } from "firebase/firestore"
import { useEffect, useState } from "react"
import { Text, View } from "react-native"


interface AnalyticsViewsProps{
  code:string | undefined
  uid:string | undefined
}
const AnalyticsViews = (props:AnalyticsViewsProps) => {

  const [views,setViews] = useState<number>(0)
  useEffect(() => {
    console.log('props',props)
    if(typeof props.uid=='undefined')return;
    if(typeof props.code=='undefined')return;
    if(props.code.length == 0)return;
    getDocs(
      collection(getFirestore(),'users',props.uid,'codes',props.code,'analytics')
    )
    .then((snapshots) => setViews(snapshots.docs.length) )
  },
  [
    props.uid,
    props.code
  ])
  return (
    <>
    <AnalyticsItem
      helperLabel="Track your views"
      label="views"
      value={views}
    />

    </>
  )
}

export default AnalyticsViews



interface AnalyticsItemProps{
  value:string | number
  label:string
  helperLabel:string
}
const AnalyticsItem = ({value,helperLabel,label}:AnalyticsItemProps) => {
  return (
    <View style={{padding:5,backgroundColor:'white',width:100,paddingHorizontal:10,borderRadius:5}} >
      <Text style={{opacity:0.5,fontSize:8,textAlign:'left',marginBottom:2}} >{helperLabel}</Text>
      <Text style={{textAlign:'left',}} >{value} {label}</Text>
    </View>
  )
}
