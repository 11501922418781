import { addDoc, collection, doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { Image, Text, TouchableOpacity, View } from "react-native";
import Button from "../../components/button/Button";
import Container from "../../components/containers/Container";
import Input from "../../components/Input/Input";
import { UserContext } from "../../context/UserContext";
import QRCODE from 'qrcode'
import { IsValidUrl } from "../../utils/validate/isValidUrl";
import { getAuth, signInAnonymously } from "firebase/auth";
import AnalyticsViews from "../../components/code/AnalyticsViews";
import { Loader } from "@googlemaps/js-api-loader"


const GoogleMaps = () => {
  const loader = new Loader({
    apiKey: "AIzaSyAnOwNghbJYqAzJsEGAcS0WN85n-78n3xQ",
    version: "weekly",
  });
  let map;
  
  loader.load().then(() => {
    map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8,
    });
  });
  return(
    <View style={{left:0,top:0,right:0,bottom:0,height:'100%',width:'100%',position:'absolute',zIndex:2,backgroundColor:'rgba(0,0,0,0.5)',display:'flex',justifyContent:'center',alignItems:'center'}} >
      <div style={{width:'60%',height:'60%'}} id="map"></div>
    </View>
  )
}

const QrcodeView = ({code}:{code:string}) => {
  const hostname =  (process.env.NODE_ENV == 'development' || process.env.FUNCTIONS_EMULATOR === 'true' ) ? 'http://localhost:19006' : 'https://cardcreate.aimdirect.nl'
  const [base64PNG, setBase64PNG] = useState('')
  const { user } = useContext(UserContext)
  const url = `${hostname}/viewer?uid=${user?.uid}&code=${code}`
  useEffect(() => {
    if(code.length == 0 ) return;
    QRCODE.toDataURL(url, {
      type:'image/png',
      errorCorrectionLevel: 'L',
      width:600,
      color: {
          light: '#0000' // Transparent background
          },
      }).then( (data) => {
        console.log('qrcode data',data)
        setBase64PNG(data)
      })
  },[code])


  const analytics = getAnalytics()
  const download = async() => {
    await logEvent(analytics,'download_qrcode', {});
    const downloadLink = document.createElement("a");
    const fileName = "qrcode.png";
    downloadLink.href = base64PNG;
    downloadLink.download = fileName;
    downloadLink.click();
  }


  return (
    <View style={{padding:2,backgroundColor:'#D9D9D6',display:'flex',justifyContent:'center',alignItems:'center',marginTop:20}} >
      {__DEV__ &&
        <View>
          <Text style={{fontSize:10}} >{url}</Text>
        </View>
      }
      <Image source={{uri:base64PNG,width:300,height:300}}  />
      <Button style={{width:'100%'}} onPress={() => download()} label="Download" />
    </View>
  )
}



// import * as Analytics from 'expo-firebase-analytics';
import { getAnalytics,logEvent, setUserId } from "firebase/analytics";
import Heading from "../../components/text/Heading";
export default function CodeView({route}){
  
  const analytics = getAnalytics()

  const { control, handleSubmit,reset } = useForm();
  const { user } = useContext(UserContext)
  const params = route?.params;
  useEffect(() => {
    if(user==null)return;
    // console.log('code',params.code)
    if(typeof params?.code=='undefined')return;
    getDoc(
      doc(getFirestore(),'users',user!.uid,'codes',params?.code)
    ).then((snap) => reset(snap.data()))
  },[user])

  const [code, setCode] = useState<string>('')
  const [showQRCode, setShowQRCode] = useState(false)
  const [isLoading,setIsLoading] = useState(false)
  const onSubmit = handleSubmit(async(values) => {
    setIsLoading(true)
    if(typeof IsValidUrl(values.url).url == 'undefined' ) {
      setIsLoading(false);
      return
    };
    
    if(typeof user?.uid == 'undefined' ){
      
      await logEvent(analytics,'sign_in_anonymously', {});
      await signInAnonymously(getAuth())
      const currentUser = getAuth().currentUser
      await setUserId(analytics,currentUser!.uid)
      await setDoc(
        doc(getFirestore(),'users',currentUser!.uid),
        {email:values.email}
      )
    }

    const currentUser = getAuth().currentUser
    console.log('currentUser',currentUser)
    await addDoc(
      collection(getFirestore(), "users", currentUser!.uid,'codes'),
      {url:values.url}
    )
      .then((snapshot) =>  {
        setCode(snapshot.id)
        setShowQRCode(true)
        setIsLoading(false);
      })
  })

  const isLoggedIn = user != null 
  return (
    <Container>

      <GoogleMaps />
      {
      !isLoggedIn && 
      <View style={{marginBottom:5}} >
        <Heading style={{textAlign:'left'}} label="Start getting the reviews you deserve" />
        <Text style={{textAlign:'left',marginVertical:10}} >Turn happy customers in to reviews within 2 minutes with a free QRcode</Text>
        <Divider />
      </View>
      }

      <AnalyticsViews code={params?.code} uid={user?.uid} />
      <Input
        label="Enter your review url"
        name="url"
        control={control}
        placeholder='www.example.com'
      />
      <NotRegisteredView
        control={control}
        name='email'
        />
      <Button
        isLoading={isLoading}
        label="Generate qr-code"
        onPress={onSubmit}
        style={{width:'100%'}}
        />
        {showQRCode && <QrcodeView code={code} />}
    </Container>
  )
}


const Divider = () => <View style={{height:2,backgroundColor:'black',opacity:0.1,marginVertical:10}} />




const NotRegisteredView = ({control,name}:{name:string,control:Control<FieldValues, object>}) => {
  const {user} = useContext(UserContext)
  if(user!=null)return null;
  return (
    <Input
        placeholder='email@email.com'
        label="Enter your email to save your QRcode"
        name={name}
        control={control}
      />
  )
}