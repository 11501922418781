import { Text, View } from "react-native";
import { useUser } from "../../context/UserContext";
import Button from "../button/Button";

const Header = ({navigation}) => {
  const {user} = useUser();
  return (
    <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}} >
      <Button label={'Home'} onPress={() => navigation.navigate('Home') } />
      <Button label={user?.uid ? 'Logout' : 'Login'} onPress={() => navigation.navigate(user?.uid ? 'Logout' : 'Login') } />
    </View>
  )
}

export default Header