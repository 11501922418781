import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import { useContext } from "react";
import { useFirebase } from "../utils/firebase";

interface UserContextProps{
  user:User | null
}
export const UserContext = createContext( {}  as UserContextProps)

const UserProvider = ({children}:{children:React.ReactChild}) => {
  useFirebase()


  const [user, setUser] = useState<UserContextProps['user']>(null)
  useEffect(() => {
    const unsub = onAuthStateChanged(getAuth(),(user) => {
      console.log('user',user)
      setUser(user)
    })
    return () => unsub()
  },[])



  return (
    <UserContext.Provider value={{user}} >
      {children}
    </UserContext.Provider>
  )

}
const useUser = () => useContext(UserContext)
export {useUser}
export default UserProvider