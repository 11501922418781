import { StatusBar } from 'expo-status-bar';
import { ActivityIndicator, Image, ImageBackground, KeyboardAvoidingView, SafeAreaView, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from 'react';
import Checkbox from 'expo-checkbox';
import { WebView } from 'react-native-webview'; 
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

import * as ImagePicker from 'expo-image-picker';
// import * as amplitude from '@amplitude/analytics-browser';
// import { init , track} from '@amplitude/analytics-browser';
import * as Linking from 'expo-linking';
import amplitude from 'amplitude-js';
import { theme } from '../../utils/theme';
import Card from './Card';
const useScript = (url:string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};












export default function CreateCard() {
const functions = getFunctions();

  const {background,backgroundColor,borderColor,borderRadius,borderWidth} = theme
  const {control,watch,handleSubmit,setValue} = useForm({defaultValues:{item0:'',item1:'',item2:'',item3:'',item4:'',item5:'',item6:'',qrcode:true,houseNumber:'',postal:'',type:'WOOD',same_invoiceAddress:false,invoice_houseNumber:'',invoice_postal:''}})
  // init('661adee5940d13ef7101f61cbf22f9b2');
  amplitude.getInstance().init("661adee5940d13ef7101f61cbf22f9b2",undefined,{includeGclid:true,includeFbclid:true,includeReferrer:true,includeUtm:true});
  useEffect(() => {
    Linking.parseInitialURLAsync()
      .then((url) => amplitude.getInstance().setUserProperties(url.queryParams))
  },[]);
  const [image, setImage] = useState(null);
  const [isLoadingImage,setIsLoadingImage] = useState(false);
  const pickImage = async () => {
    setIsLoadingImage(true)
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      base64:true,
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log(result);

    if (!result.cancelled) {
      const getGrayScaleImage = httpsCallable(functions, 'grayScale');
      const img =(await getGrayScaleImage(result.base64)).data
      console.log('IMG','data:image/jpeg;base64,'+img)
      setImage('data:image/png;base64,'+img);
      setValue('img','data:image/png;base64,'+img)
      setIsLoadingImage(false)
    }
  };



  const [selectedType,setSelectedType] = useState<'WOOD' | "BLACK">('WOOD')
  useEffect(() => setValue('type',selectedType),[selectedType])
  const isSelected = (item:'WOOD' | "BLACK") => {
    if(item == selectedType) return 'blue'
    return borderColor
  }






  const [itemIndex,setItemIndex] = useState(0)
  const [isLoading,setIsLoading] = useState(false)
  const maxItemIndex = 9
  const updateItemIndex = (index:number) => {
    amplitude.getInstance().logEvent(`Selected item: ${index}`);
    // track(`Selected item: ${index}`);
    // console.log('index',index,index == maxItemIndex)
    if(index == -1)return;
    else if(index == maxItemIndex){
      setItemIndex(index)
      console.log('MATCH')
      setIsLoading(true)
      handleSubmit(
        (values) => {
          console.log('MATCH',"handleSubmit")
          const createPayment = httpsCallable(functions, 'createPayment');
          createPayment(values)
            .then((result) => {
              // Read result of the Cloud Function.
              /** @type {any} */
              const data = result.data;
              window.location.href = data._links.checkout.href
              // console.log('checkout',data._links.checkout.href)
              // result.data.get
              console.log('data',data)
            }).finally(() => setIsLoading(false));
          console.log('submit',values)
        }
      )()
    }
    else setItemIndex(index)
  }


  return (
    <KeyboardAvoidingView style={{flex:1}} >
      <View style={{height:'100%'}} >
        <View style={{height:'45%',backgroundColor:'rgba(30,30,30,0.10)'}} >
          <View style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
            <Image source={require('./../../assets/trustpilot-logo.webp')} resizeMode='contain' style={{width:100,height:30}} />
          </View>
          <Card image={image} selectedType={selectedType} watch={watch} />
        </View>

        <View style={{marginTop:20,marginHorizontal:20,display:'flex',flexDirection:'column',justifyContent:'space-around'}} >
          <View style={{}} >

          {itemIndex==0 && 
          <>

            <View style={{marginVertical:10}} >

              <Text style={{opacity:0.5}} >Selecteer jouw type AIMCard</Text>
              <Text style={{fontSize:10,color:'blue',opacity:.5}} >tijdelijk GRATIS verzending</Text>
            </View>
            <View style={{display:'flex',flexDirection:'column',justifyContent:'space-between',height:120}} >

              <TouchableOpacity onPress={() => setSelectedType('WOOD')} style={{width:250,height:50,backgroundColor,borderColor:isSelected('WOOD'),borderWidth:1,borderRadius,padding:5,display:'flex',justifyContent:'flex-start',alignItems:'center',flexDirection:'row'}} >
                <Image style={{height:30,width:30,borderRadius}} source={require('./../../assets/wood.png')} />
                <View style={{display:'flex',flexDirection:'column',width:'80%'}} >
                  <View style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}} >
                    <View>
                      <Text style={{marginLeft:10,fontWeight:'bold'}} >Hout</Text>
                      <Text style={{marginLeft:10,fontSize:8}} >donkerkleurig</Text>
                    </View>
                    <View style={{display:'flex',justifyContent:'space-between',marginLeft:10,backgroundColor:''}} >
                      <Text style={{fontWeight:'bold','textDecorationLine': 'line-through',fontSize:10,opacity:0.8,color:'red'}} >€ 48.39 (ex. btw)</Text>
                      <Text style={{fontWeight:'bold'}} >€ 39,99 (ex. btw)</Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => setSelectedType('BLACK')} style={{width:250,height:50,backgroundColor,borderRadius,borderColor:isSelected('BLACK'),borderWidth:1,  padding:5,display:'flex',justifyContent:'flex-start',alignItems:'center',flexDirection:'row'}} >
                <Image style={{height:30,width:30,borderRadius}} source={require('./../../assets/black.png')} />
                <View style={{display:'flex',flexDirection:'column',width:'80%'}} >
                  <View style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}} >
                    <View>

                      <Text style={{marginLeft:10,fontWeight:'bold'}} >Metaal</Text>
                      <Text style={{marginLeft:10,fontSize:8}} >mat zwart</Text>

                    </View>
                    <View style={{display:'flex',justifyContent:'space-between',marginLeft:10,backgroundColor:''}} >
                      <Text style={{fontWeight:'bold','textDecorationLine': 'line-through',fontSize:10,opacity:0.8,color:'red'}} >€ 72.59 (ex. btw)</Text>
                      <Text style={{fontWeight:'bold'}} >€ 59,99 (ex. btw)</Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>

            </View>
          </>
          }
          </View>


          {itemIndex==1 && <Controller
            name={`item0`}
            control={control}
            render={({field:{onChange,value}}) => <TextInput maxLength={25} autoCapitalize='none' value={value} onChangeText={onChange}  placeholder='Naam' style={{backgroundColor,padding:10,borderWidth,borderColor,borderRadius}} />}
          />}

          {itemIndex==2 && <Controller
            name={`item1`}
            control={control}
            render={({field:{onChange,value}}) => <TextInput maxLength={28}  autoCapitalize='none' value={value} onChangeText={onChange}  placeholder='Functie / Bedrijf' style={{backgroundColor,padding:10,borderWidth,borderColor,borderRadius}} />}
          />}

          {itemIndex==3 &&<Controller
            name={`item3`}
            control={control}
            render={({field:{onChange,value}}) => <TextInput  maxLength={28} autoCapitalize='none' value={value} onChangeText={onChange}  placeholder='Email' style={{backgroundColor,padding:10,borderWidth,borderColor,borderRadius}} />}
          />}
          {itemIndex==4 &&<Controller
            name={`item4`}
            control={control}
            render={({field:{onChange,value}}) => <TextInput  maxLength={28}  autoCapitalize='none' value={value} onChangeText={onChange}  placeholder='Telefoon nummer' style={{backgroundColor,padding:10,borderWidth,borderColor,borderRadius}} />}
          />}

          {itemIndex==5 && <Controller
            name={`item5`}
            control={control}
            render={({field:{onChange,value}}) => <TextInput  maxLength={28} autoCapitalize='none' value={value} onChangeText={onChange}  placeholder='Website' style={{backgroundColor,padding:10,borderWidth,borderColor,borderRadius}} />}
          />}
          
          {itemIndex==6 &&
          <>
          {/* <Text></Text> */}
          <Controller
            name={`item6`}
            control={control}
            render={({field:{onChange,value}}) => <TextInput  maxLength={28} autoCapitalize='none' value={value} onChangeText={onChange}  placeholder='Adres' style={{backgroundColor,padding:10,borderWidth,borderColor,borderRadius}} />}
            />
          </>
          }

          {itemIndex==7 && (
              <View>
                  <View style={{display:'flex',justifyContent:'flex-start',alignItems:'center',flexDirection:'row'}} >
                    <Controller
                      name='qrcode'
                      control={control}
                      render={({field:{onChange,value}}) => <Checkbox  style={{margin: 8}} value={value} onValueChange={onChange} /> }
                      />
                    <Text>Wil je een unieke QR code op jouw AIM Card?</Text>
                  </View>
                    <Text style={{opacity:.5, textAlign:'left',fontSize:10,width:'80%'}} >Kosteloos & laat na scannen dezelfde pagina zien als ingesteld staat op jouw AIM Card.</Text>
                  {/* <View style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}} > */}
                  {/* </View> */}
                  {Boolean(selectedType == 'WOOD') && 
                    <>
                      <View style={{marginTop:20}} >
                        <TouchableOpacity onPress={() => pickImage()} style={{borderRadius,borderWidth,borderColor,backgroundColor:'rgba(0,0,0,0.05)',height:100,width:'100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}} >
                          {isLoadingImage && <ActivityIndicator />}
                          {image?.length ? <Image source={{uri:image}} style={{height:'100%',width:50}} resizeMode='contain' /> : null}
                          <Text style={{marginVertical:4,opacity:0.5}} >{image?.length ? 'Logo aanpassen' : 'Logo toevoegen'}</Text>
                        </TouchableOpacity>
                      </View>
                    </>
                  }

             </View>
            )}

          {itemIndex==8 && (
            <View>
              <Text style={{marginVertical:3,opacity:0.5}} >Vul hier het adres in waar je AIM Card naartoe mag worden verstuurd</Text>
              <Controller
                name='houseNumber'
                control={control}
                render={({field:{onChange,value}}) => <TextInput value={value} onChangeText={onChange}  placeholder='straat 00' style={{backgroundColor,padding:10,borderWidth,borderColor,borderRadius,width:'100%'}} />}
              />
                  <Controller
                      name='postal'
                      control={control}
                      render={({field:{onChange,value}}) => <TextInput  value={value} onChangeText={onChange}  placeholder='0000 AB' style={{backgroundColor,padding:10,borderWidth,borderColor,borderRadius,width:'100%',marginTop:5}} />}
                    />
              <Controller
                name='same_invoiceAddress'
                defaultValue={true}
                control={control}
                render={({field:{onChange,value}}) => (
                  <View style={{marginTop:10,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <Checkbox  style={{margin: 8}} value={value} onValueChange={onChange} />
                    <Text style={{marginVertical:1,opacity:0.5}} >Alternatief factuuradres</Text>
                  </View>
                  )
              }
                />
                {watch('same_invoiceAddress') && (
                  <View>
                    <Controller
                      name='invoice_houseNumber'
                      control={control}
                      render={({field:{onChange,value}}) => <TextInput value={value} onChangeText={onChange}  placeholder='straat 00' style={{backgroundColor,padding:10,borderWidth,borderColor,borderRadius,width:'100%',marginTop:5}} />}
                    />
                    <Controller
                      name='invoice_postal'
                      control={control}
                      render={({field:{onChange,value}}) => <TextInput  value={value} onChangeText={onChange}  placeholder='0000 AB' style={{backgroundColor,padding:10,borderWidth,borderColor,borderRadius,width:'100%',marginTop:5}} />}
                    />
                  </View>
                )}
            </View>
          )}


          {isLoading ?
          <View style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
            <Text style={{textAlign:'center'}} >Een ogenblik geduld. Je wordt doorgestuurd naar de betaalpagina</Text>
            <TouchableOpacity style={{marginTop:20,opacity:0.6}} onPress={() =>  updateItemIndex(itemIndex-1)} >
              <Text>Terug</Text>
            </TouchableOpacity>
          </View>
          :
          <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:10}} >
            {itemIndex != 0 && 
              <TouchableOpacity  onPress={() =>  updateItemIndex(itemIndex-1)} style={{borderRadius, display:'flex',justifyContent:'center',alignItems:'center',padding:10,backgroundColor,width:'30%'}} >
              <Text>terug</Text>
            </TouchableOpacity>}
            <TouchableOpacity  onPress={() =>  updateItemIndex(itemIndex+1)} style={{borderRadius, display:'flex',justifyContent:'center',alignItems:'center',padding:10,backgroundColor,width:(itemIndex==0) ? '100%' : '60%'}} >
              <Text>Volgende</Text>
            </TouchableOpacity>
          </View>
          }

        </View>
      </View>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

