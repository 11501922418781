import { StyleSheet, Text, TextInput, View } from "react-native";
import Button from '../components/button/Button';
import { Controller, useForm } from "react-hook-form";
import { styles } from "../components/styles";
import Input from "../components/Input/Input";
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'
import { useFirebase } from "../utils/firebase";
import Container from "../components/containers/Container";

// const auth = getAuth();
const LoginView = ({navigation}) => {
  const {control,handleSubmit} = useForm()
  const onSubmit = handleSubmit(
    async(values) => {
    console.log('onSubmit',values)
    await signInWithEmailAndPassword(getAuth(),values.email, values.password)
      .then((data) => {
        console.log('data',data);
        navigation.navigate('Home')
      })
      .catch(console.log)
  })

  return (
    <Container style={{display:'flex',justifyContent:'center'}} >
    

      <Input
        label="Email"
        name='email'
        control={control}
      />

      <Input
        label="Wachtwoord"
        name='password'
        control={control}
      />

      <Button style={{width:'100%'}} onPress={onSubmit} label='Login'  />

    </Container>
  )
}
export default LoginView
