import { useNavigation } from "@react-navigation/native"
import { collection, getDocs, getFirestore } from "firebase/firestore"
import { useContext, useEffect, useState } from "react"
import { FlatList, Text, TouchableOpacity, View } from "react-native"
import { UserContext } from "../../context/UserContext"
import { Code } from "../../types"




const CodeItem = ({item}:{item:Code}) => {
  const {navigate} = useNavigation()
  return (
    <TouchableOpacity onPress={() => navigate('Code',{code:item.id})} style={{height:30,backgroundColor:'white',paddingHorizontal:20}} >
      <Text>{item.data.url}</Text>
    </TouchableOpacity>
  )
}

export default function CodesView(){
  const [data,setData] = useState<Code[]>([])
  const {user} = useContext(UserContext)
  useEffect(() =>{
    console.log('getting all data')
    if(user==null)return;
    getDocs(
      collection(getFirestore(),'users',user!.uid,'codes')
    )
    .then((snapshots) => setData( snapshots.docs.map(doc => ({id:doc.id,data:doc.data() as Code['data']})))  )
  },[user])
  return (

    <FlatList
      data={data}
      renderItem={({item}) => <CodeItem item={item} />}
    />

  )
}