import { useForm } from "react-hook-form";
import { Image, Text, View } from "react-native";
import Button from "../../components/button/Button";
import Container from "../../components/containers/Container";
import Input from "../../components/Input/Input";
import {createUserWithEmailAndPassword, getAuth, signInAnonymously} from 'firebase/auth'
import Heading from "../../components/text/Heading";
import { v4 as uuidv4 } from 'uuid';
const RegisterView = () => {

  const {control,handleSubmit} = useForm();

  const onSubmit = handleSubmit((values) => {
    createUserWithEmailAndPassword(getAuth(),values.email, uuidv4())
  })
  return (
    <>
    {/* <Image
      source={require('./../../assets/resturant.jpg')}
      
      style={{position:'absolute',left:0,right:0,top:0,bottom:0,opacity:0.3,resizeMode:'cover'}}
      /> */}
    <Container style={{display:'flex',justifyContent:'center'}} >
      
      <Heading style={{textAlign:'center'}} label="Start getting the reviews you deserve" />
      <Text style={{textAlign:'center'}} >Turn happy customers in to Reviews within 2 minutes with a free QRcode</Text>

      <Input
        label="Email"
        name='email'
        control={control}
      />


      <Button style={{width:'100%'}} onPress={onSubmit} label='Get my qrcode'  />

    </Container>
    </>
  )
}

export default RegisterView;